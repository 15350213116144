import { createRouter, createWebHistory } from 'vue-router'

const routes = [
{
	path: '/',
	name: 'Home',
		component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
},
{
	path: '/privacy-policy',
	name: 'Privacy Policy',
		component: () => import(/* webpackChunkName: "about" */ '../views/Privacy.vue')
}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to) {
		if (to.hash){
			return new Promise((resolve) => {
				setTimeout(() => {
					resolve({
						selector: to.hash,
						behavior: 'smooth',
					})
				}, 1000)
			})
		} else {
			return {
				top: 0
			}
		}
	}
})

export default router
