<template>
	<footer class="mastfoot color-brand-0 family-heading">
		<div class="container">
			<div class="mastfoot-container bg-brand-950 flow-l">
				<div class="text-center">
					<router-link v-if="'Home' !== $route.name" :to="{ path: '/' }" title="Click to go back to the top">
						<logo class="mastfoot-logo fill-brand-50" />
					</router-link>

					<a v-else href="#" title="Click to go back to the top">
						<logo class="mastfoot-logo fill-brand-50" />
					</a>
				</div>

				<div>
					<div class="mastfoot_contact-row row family-heading">
						<div class="col-auto">
							<a href="mailto:info@smitchwayproperties.co.uk" title="Email S.Mitchway Properties LTD">info@smitchwayproperties.co.uk</a>
						</div>

						<div class="col-auto">
							<a href="tel:0123456789" title="Call S.Mitchway Properties LTD">07904 343 611</a>
						</div>
					</div>
				</div>

				<div>
					<div class="mastfoot_navigation-row row">
						<nav class="mastfoot_navigation">
							<ul class="row justify-content-center">
								<li class="col-12 col-sm-auto">
									<a href="/#about" title="Click to scroll to read about us">About</a>
								</li>
								<li class="col-12 col-sm-auto">
									<a href="/#process" title="Click to scroll to our services section">Services</a>
								</li>
								<li class="col-12 col-sm-auto">
									<a href="/#contact" title="Click to scroll to our weekly email signup area">Contact</a>
								</li>
								<li class="col-12 col-sm-auto">
									<router-link to="/privacy-policy" title="Click to view our privacy policy">Privacy Policy</router-link>
								</li>
							</ul>
						</nav>

						<nav class="mastfoot_social-media">
							<a target="_blank" href="https://www.instagram.com/s.mitchway_properties" title="Follow us on Instagram">
								<instagram/>
							</a>
						</nav>
					</div>
				</div>

				<div class="mastfoot_company-information font-small flow-m">
					<div>
						<div class="row">
							<div class="col-12 col-sm-auto">
								<p>&copy; S.Mitchway Properties LTD</p>
							</div>

							<div class="col-12 col-sm-auto">
								<p>Company number: 15354485</p>
							</div>

							<div class="col-12 col-sm-auto">
								<p><a rel="nofollow noreferrer" target="_blank" href="https://www.thepims.co.uk/">PIMS: 44655</a></p>
							</div>
						</div>
					</div>

					<p>Built by <a target="_blank" rel="nofollow noreferrer" href="https://www.kaluna.co.uk">Kaluna</a>.</p>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
	export default {
		name: "Mastfoot"
	}
</script>

<style lang="scss" scoped>
	nav {
		ul {
			padding-left: 0;
		}

		li {
			list-style: none;
			padding-left: 0;

			@include media-breakpoint-up(sm) {
				&:not(:first-child) {
					list-style: disc;
					padding-left: var(--space-s);
				}
			}
		}

		li:not(:last-child) {
			margin-right: var(--space-m);
		}

		a {
			--anchor-color: hsla(var(--brand-0), var(--alpha));

			&:hover {
				--anchor-color: hsla(var(--brand-400), var(--alpha));
			}
		}
	}

	.mastfoot {
		margin-bottom: var(--space-l);

		@include media-breakpoint-up(sm) {
			text-align: center;
		}

		&-container {
			padding-top: var(--space-3xl);
			padding-bottom: var(--space-3xl);
			padding-left: var(--space-l);
			padding-right: var(--space-l);
			clip-path: polygon(var(--space-3xl) 0%, 100% 0, 100% 0, 100% calc(100% - var(--space-3xl)), calc(100% - var(--space-3xl)) 100%, 0 100%, 0 100%, 0% var(--space-3xl));
		}

		&_contact-row {
			line-height: 1;
			--theme-gutter-y: var(--space-m);

			@include media-breakpoint-up(sm) {
				justify-content: center;

				> div:last-child {
					&:before {
						content: '/';
						margin-right: var(--theme-gutter-x);
						margin-left: calc(var(--theme-gutter-x) * -1);
					}
				}
			}
		}

		&_company-information .row {
			--theme-gutter-y: var(--space-2xs);

			@include media-breakpoint-up(sm) {
				justify-content: center;

				> div:not(:last-child) p {
					&:after {
						display: inline-block;
						vertical-align: middle;
						content: '/';
						margin-left: var(--theme-gutter-x);
						margin-right: calc(var(--theme-gutter-x) * -1);
					}
				}
			}
		}

		&_navigation-row {
			ul {
				margin-bottom: 0;
			}

			@include media-breakpoint-up(sm) {
				justify-content: center;
			}
		}

		&-logo {
			width: 280px;
			max-width: 80%;
			height: auto;
		}

		nav a {
			--anchor-color: var(--brand-0);
		}

		&_social-media {
			list-style: none;

			@include media-breakpoint-up(sm) {
				margin-left: var(--space-m);
			}

			svg {
				width: var(--space-m);
				height: auto;
			}
		}
	}
</style>