<template>
	<section class="cards">
		<div class="identifier" v-if="typeof data.id !== 'undefined'" :id="data.id"></div>

		<div class="container flow-l">
			<component class="text-center" v-if="Object.values(data.title).length > 0" :is="'title'" :data="data.title"></component>
					
			<div v-if="Object.values(data.cards).length > 0">
				<div class="cards-row row justify-content-center">
					<div v-for="(card, index) in data.cards" :key="index" class="col-12 col-lg-6 col-xl-auto usp-wrapper">
						<article class="usp flow-l usp-column">
							<component class="text-center" v-if="Object.values(card.title).length > 0" :is="'title'" :data="card.title"></component>
							
							<component v-if="typeof card.copy !== 'undefined'" :is="'copy'" :data="card.copy"></component>

							<div v-if="Object.values(card.usps).length > 0">
								<ul class="card-usps flow-m">
									<li v-for="(usp, index) in card.usps" :key="index">
										<div class="usp-row row">
											<component class="col-12 col-sm-auto usp-icon" v-if="usp.icon" :data="usp.icon" :is="'icon'"></component>
										
											<component class="col-12 col-sm font-reset" v-if="typeof usp.copy !== 'undefined'" :is="'copy'" :data="usp.copy"></component>
										</div>		
									</li>
								</ul>
							</div>
							
							<component class="font-base" v-if="typeof card.strapline !== 'undefined'" :is="'copy'" :data="card.strapline"></component>

							<component v-if="typeof card.button !== 'undefined'" :is="'button'" :data="card.button"></component>
						</article>					
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: "Cards",

		props: [
			"data"
		]
	}
</script>

<style lang="scss" scoped>
	.cards {
		margin-top: var(--space-t);
		padding-bottom: var(--space-t);

		background: repeating-linear-gradient(
			135deg,
			hsla(var(--brand-0), 1),
			hsla(var(--brand-0), 1) var(--space-2xl),
			hsla(var(--brand-50), .33) var(--space-2xl),
			hsla(var(--brand-50), .33) calc(var(--space-2xl) * 2)
		);

		&-row {
			margin-top: 0;
			--theme-gutter-y: var(--space-l);
			
			@include media-breakpoint-up(lg) {
				--theme-gutter-x: var(--space-l);
			}
		}
	}

	.card-usps {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.usp {
		height: 100%;

		&-icon {
			max-width: 25px;
		}

		&-column {
			padding: var(--space-xl);
			max-width: 525px;
			margin-inline: auto;
			background-color: hsla(var(--brand-50), var(--alpha));
			clip-path: polygon(var(--space-3xl) 0%, 100% 0, 100% 0, 100% calc(100% - var(--space-3xl)), calc(100% - var(--space-3xl)) 100%, 0 100%, 0 100%, 0% var(--space-3xl));
		}

		&-row {
			--theme-gutter-x: var(--space-s);
			--theme-gutter-y: var(--space-s);
		}

		&-wrapper {
			&:last-child .usp-column {
				background-color: hsla(var(--brand-400), var(--alpha));
				color: hsla(var(--brand-950), var(--alpha));
			}
		}

		&::v-deep {
			.theme-icon svg {
				width: 25px;
				height: auto;
			}
		}
	}
</style>