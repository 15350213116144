<template>
	<div class="theme-button">
		<a 	
			class="button"
			:class="typeof data.options !== 'undefined' ? data.options.join(' ') : null"
			:href="data.url"
			:target="typeof data.target !== 'undefined' ? data.target : '_self'"
			:title="data.title"
			v-html="data.title" />
	</div>
</template>

<script>
	export default {
		name: "Button", 

		props: [
			"data"
		]
	}
</script>

<style lang="scss" scoped>
	.button {
		font-family: var(--family-heading);
		font-size: var(--font-base);
		font-weight: 900;
		letter-spacing: inherit;
		border: 3px solid transparent;

		&[class*="bg-"] {
			padding: 0 var(--space-m);
			display: inline-block;
			vertical-align: middle;
			line-height: 49px;
			text-decoration: none;

			&:hover {
				border-color: var(--section-color);
				color: var(--section-color);
				background-color: transparent;
			}
		}

		&:not([class*="bg-"]) {
			text-decoration: none;

			&:hover {
				--anchor-color: hsla(var(--brand-200), var(--alpha));
				color: var(--anchor-color);
			}
		}
	}
</style>