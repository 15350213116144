<template>
	<section class="hero">
		<div class="hero-body">
			<div class="container flow-l">
				<component class="hero-title" v-if="Object.values(data.title).length > 0" :is="'title'" :data="data.title"></component>

				<component class="hero-copy font-mid" v-if="typeof data.copy !== 'undefined'" :is="'copy'" :data="data.copy"></component>

				<div v-if="Object.values(data.buttons).length > 0">
					<div class="row align-items-center">
						<div v-for="(button, index) in data.buttons" :key="index" class="col-auto">
							<component :is="'button'" :data="button"></component>
						</div>
					</div>
				</div>
			</div>
		</div>

		<component width="2560px" height="1260px" class="hero-image" :is="'image'" :data="data.image" :preload="true"></component>
	</section>
</template>

<script>
	export default {
		name: 'Hero',

		props: [ 
			'data'
		],

		mounted() { 
			// console.log(this.data);
		}
	}
</script>

<style lang="scss" scoped>
	.hero {
		height: 95vh;
		position: relative;
		padding: 0;

		&-body {
			position: absolute;
			z-index: 1;
			left: 0;
			width: 100%;
			bottom: 50vh;
			transform: translateY(calc(50% + var(--space-l)));
		}

		&-image {
			height: inherit;

			&::v-deep {
				img {
					max-height: 1260px;
				}
			}
		}

		&-copy {
			max-width: 45ch;
		}
	}
</style>