<template>
	<div class="theme-title">
		<component :class="data.font_size" :is="data.tag" v-html="data.title"></component>
	</div>	
</template>

<script>
	export default {
		name: "Title",

		props: [ 
			'data'
		]
	}
</script>