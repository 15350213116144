<template>
	<masthead/>
	
	<router-view/>

	<mastfoot/>
</template>

<style lang="scss">
	:root {
		// alpha.
		--alpha: 1;

		// gutters.
		--theme-gutter-x: 1rem;
		--theme-gutter-y: 0;

		// --container-width.
		--container-width: 1640px;

		// masthead height
		--masthead-height: 95px;

		// brand colours.
		--brand-0:		255, 255%, 255%;
		--brand-50:		44, 44%, 95%;
		--brand-100: 	48, 41%, 88%;
		--brand-200: 	44, 42%, 77%;
		--brand-300: 	42, 43%, 64%;
		--brand-400: 	40, 42%, 58%;
		--brand-500: 	39, 6%, 47%;
		--brand-600: 	34, 8%, 40%;
		--brand-700: 	30, 5%, 33%;
		--brand-800: 	30, 1%, 29%;
		--brand-900: 	195, 3%, 26%;
		--brand-950: 	0, 1%, 14%;

		// spacers.
		--space-3xs:	clamp(0.25rem, 0.2303rem + 0.0986vw, 0.3125rem);
		--space-2xs: 	clamp(0.5rem, 0.4803rem + 0.0986vw, 0.5625rem);
		--space-xs:	 	clamp(0.75rem, 0.7106rem + 0.1972vw, 0.875rem);
		--space-s:	 	clamp(1rem, 0.9606rem + 0.1972vw, 1.125rem);
		--space-m:	 	clamp(1.5rem, 1.4408rem + 0.2959vw, 1.6875rem);
		--space-l:	 	clamp(2rem, 1.9211rem + 0.3945vw, 2.25rem);
		--space-xl:	 	clamp(3rem, 2.8817rem + 0.5917vw, 3.375rem);
		--space-2xl: 	clamp(4rem, 3.8422rem + 0.789vw, 4.5rem);
		--space-3xl: 	clamp(6rem, 5.7633rem + 1.1834vw, 6.75rem);

		// font sizes.
		--font-smallest:	clamp(0.625rem, 0.8191rem + -0.1894vw, 0.7813rem);
		--font-small: 		clamp(0.8838rem, 0.9505rem + -0.0652vw, 0.9375rem);
		--font-reset: 		1rem;
		--font-base: 		clamp(1.125rem, 1.0947rem + 0.1515vw, 1.25rem);
		--font-mid: 		clamp(1.35rem, 1.2488rem + 0.5061vw, 1.7675rem);
		--font-lead: 		clamp(1.62rem, 1.4068rem + 1.0659vw, 2.4994rem);
		--font-large: 		clamp(1.9438rem, 1.5583rem + 1.9273vw, 3.5338rem);
		--font-larger: 		clamp(2.3325rem, 1.6866rem + 3.2295vw, 4.9969rem);
		--font-largest:		clamp(2.7994rem, 1.7651rem + 5.1712vw, 7.0656rem);

		// font family
		--family-heading:	mixta-didone, serif;
		--family-body:		neue-haas-unica, sans-serif;
	}	

	/* Box sizing rules */
	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}

	/* Remove default margin */
	body,
	h1,
	h2,
	h3,
	h4,
	p,
	figure,
	blockquote,
	dl,
	dd {
		margin: 0;
	}

	/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
	ul[role='list'],
	ol[role='list'] {
		list-style: none;
	}

	/* Set core root defaults */
	html {
		scroll-behavior: smooth;
	}

	/* Set core body defaults */
	body {
		min-height: 100vh;
		text-rendering: optimizeSpeed;
		line-height: 1.8;
		color: hsla(var(--brand-950), 1);
		font-family: var(--family-body);
		font-weight: 300;
		font-size: 18px;
		letter-spacing: 0.03rem;
	}

	/* A elements that don't have a class get default styles */
	a:not([class]) {
		text-decoration-skip-ink: auto;
	}

	/* Make images easier to work with */
	img,
	picture {
		max-width: 100%;
		width: 100%;
		display: block;
		height: auto;
		object-fit: cover;
	}

	/* Standardise SVGs */
	svg {
		--svg-width: 1rem;
		--svg-height: 1rem;
		width: var(--svg-width);
		height: var(--svg-height);
		display: inline-block;
		vertical-align: middle;
		fill: currentcolor;
	}

	/* Inherit fonts for inputs and buttons */
	input,
	button,
	textarea,
	select {
		font: inherit;
	}

	/* fonts */
	h1, h2, h3, h4, h5, h6 {
		font-family: var(--family-heading);
		line-height: 1.1;
	}

	/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
	@media (prefers-reduced-motion: reduce) {
		html:focus-within {
			scroll-behavior: auto;
		}

		*,
		*::before,
		*::after {
			animation-duration: 0.01ms;
			animation-iteration-count: 1;
			transition-duration: 0.01ms;
			scroll-behavior: auto;
		}
	}

	::selection {
		background: hsla(var( --brand-400 ), 1);
		color: hsla(var( --brand-950), 1);
	}

	::-moz-selection {
		background: hsla(var( --brand-400 ), 1);
		color: hsla(var( --brand-950), 1);
	}

	// font utilities.
	.font-smallest {
		font-size: var(--font-smallest);
	}

	.font-small {
		font-size: var(--font-small);
	}

	.font-reset {
		font-size: var(--font-reset);
	}

	.font-base {
		font-size: var(--font-base);
	}

	.font-mid {
		font-size: var(--font-mid);
	}

	.font-lead {
		font-size: var(--font-lead);
	}

	.font-large {
		font-size: var(--font-large);
	}

	.font-larger {
		font-size: var(--font-larger);
	}

	.font-largest {
		font-size: var(--font-largest);
	}

	.family-heading {
		font-family: var(--family-heading);
		font-weight: 600;
		letter-spacing: 0.04rem;
	}

	// spacers.
	.flow-3xs > * + * {
		margin-top: var(--space-3xs);
		margin-bottom: 0;
	}

	.flow-2xs > * + * {
		margin-top: var(--space-2xs);
		margin-bottom: 0;
	}

	.flow-xs > * + * {
		margin-top: var(--space-xs);
		margin-bottom: 0;
	}

	.flow-s > * + * {
		margin-top: var(--space-s);
		margin-bottom: 0;
	}

	.flow-m > * + * {
		margin-top: var(--space-m);
		margin-bottom: 0;
	}

	.flow-l > * + * {
		margin-top: var(--space-l);
		margin-bottom: 0;
	}

	.flow-xl > * + * {
		margin-top: var(--space-xl);
		margin-bottom: 0;
	}

	.flow-2xl > * + * {
		margin-top: var(--space-2xl);
		margin-bottom: 0;
	}

	.flow-3xl > * + * {
		margin-top: var(--space-3xl);
		margin-bottom: 0;
	}

	// bg.
	.bg-brand-0 {
		background-color: hsla(var(--brand-0), var(--alpha));
	}

	.fill-brand-0 {
		fill: hsla(var(--brand-0), var(--alpha));
	}

	section.bg-brand-0 {
		--section-bg: hsla(var(--brand-0), var(--alpha));
	}

	.bg-brand-50 {
		background-color: hsla(var(--brand-50), var(--alpha));
	}

	.fill-brand-50 {
		fill: hsla(var(--brand-50), var(--alpha));
	}

	section.bg-brand-50 {
		--section-bg: hsla(var(--brand-50), var(--alpha));
	}

	.bg-brand-100 {
		background-color: hsla(var(--brand-100), var(--alpha));
	}

	.fill-brand-100 {
		fill: hsla(var(--brand-100), var(--alpha));
	}

	section.bg-brand-100 {
		--section-bg: hsla(var(--brand-100), var(--alpha));
	}

	.bg-brand-200 {
		background-color: hsla(var(--brand-200), var(--alpha));
	}

	.fill-brand-200 {
		fill: hsla(var(--brand-200), var(--alpha));
	}

	section.bg-brand-200 {
		--section-bg: hsla(var(--brand-200), var(--alpha));
	}

	.bg-brand-300 {
		background-color: hsla(var(--brand-300), var(--alpha));
	}

	.fill-brand-300 {
		fill: hsla(var(--brand-300), var(--alpha));
	}

	section.bg-brand-300 {
		--section-bg: hsla(var(--brand-300), var(--alpha));
	}

	.bg-brand-400 {
		background-color: hsla(var(--brand-400), var(--alpha));
	}

	.fill-brand-400 {
		fill: hsla(var(--brand-400), var(--alpha));
	}

	section.bg-brand-400 {
		--section-bg: hsla(var(--brand-400), var(--alpha));
	}

	.bg-brand-500 {
		background-color: hsla(var(--brand-500), var(--alpha));
	}

	.fill-brand-500 {
		fill: hsla(var(--brand-500), var(--alpha));
	}

	section.bg-brand-500 {
		--section-bg: hsla(var(--brand-500), var(--alpha));
	}

	.bg-brand-600 {
		background-color: hsla(var(--brand-600), var(--alpha));
	}

	.fill-brand-600 {
		fill: hsla(var(--brand-600), var(--alpha));
	}

	section.bg-brand-600 {
		--section-bg: hsla(var(--brand-600), var(--alpha));
	}

	.bg-brand-700 {
		background-color: hsla(var(--brand-700), var(--alpha));
	}

	.fill-brand-700 {
		fill: hsla(var(--brand-700), var(--alpha));
	}

	section.bg-brand-700 {
		--section-bg: hsla(var(--brand-700), var(--alpha));
	}

	.bg-brand-800 {
		background-color: hsla(var(--brand-800), var(--alpha));
	}

	.fill-brand-800 {
		fill: hsla(var(--brand-800), var(--alpha));
	}

	section.bg-brand-800 {
		--section-bg: hsla(var(--brand-800), var(--alpha));
	}

	.bg-brand-900 {
		background-color: hsla(var(--brand-900), var(--alpha));
	}

	.fill-brand-900 {
		fill: hsla(var(--brand-900), var(--alpha));
	}

	section.bg-brand-900 {
		--section-bg: hsla(var(--brand-900), var(--alpha));
	}

	.bg-brand-950 {
		background-color: hsla(var(--brand-950), var(--alpha));
	}

	.fill-brand-950 {
		fill: hsla(var(--brand-950), var(--alpha));
	}

	section.bg-brand-950 {
		--section-bg: hsla(var(--brand-950), var(--alpha));
	}

	// colour.
	.color-brand-0 {
		color: hsla(var(--brand-0), var(--alpha));
	}

	section.color-brand-0 {
		--section-color: hsla(var(--brand-0), var(--alpha));
	}

	.color-brand-50 {
		color: hsla(var(--brand-50), var(--alpha));
	}

	section.color-brand-50 {
		--section-color: hsla(var(--brand-50), var(--alpha));
	}

	.color-brand-100 {
		color: hsla(var(--brand-100), var(--alpha));
	}

	section.color-brand-100 {
		--section-color: hsla(var(--brand-100), var(--alpha));
	}

	.color-brand-200 {
		color: hsla(var(--brand-200), var(--alpha));
	}

	section.color-brand-200 {
		--section-color: hsla(var(--brand-200), var(--alpha));
	}

	.color-brand-300 {
		color: hsla(var(--brand-300), var(--alpha));
	}

	section.color-brand-300 {
		--section-color: hsla(var(--brand-300), var(--alpha));
	}

	.color-brand-400 {
		color: hsla(var(--brand-400), var(--alpha));
	}

	section.color-brand-400 {
		--section-color: hsla(var(--brand-400), var(--alpha));
	}

	.color-brand-500 {
		color: hsla(var(--brand-500), var(--alpha));
	}

	section.color-brand-500 {
		--section-color: hsla(var(--brand-500), var(--alpha));
	}

	.color-brand-600 {
		color: hsla(var(--brand-600), var(--alpha));
	}

	section.color-brand-600 {
		--section-color: hsla(var(--brand-600), var(--alpha));
	}

	.color-brand-700 {
		color: hsla(var(--brand-700), var(--alpha));
	}

	section.color-brand-700 {
		--section-color: hsla(var(--brand-700), var(--alpha));
	}

	.color-brand-800 {
		color: hsla(var(--brand-800), var(--alpha));
	}

	section.color-brand-800 {
		--section-color: hsla(var(--brand-800), var(--alpha));
	}

	.color-brand-900 {
		color: hsla(var(--brand-900), var(--alpha));
	}

	section.color-brand-900 {
		--section-color: hsla(var(--brand-900), var(--alpha));
	}

	.color-brand-950 {
		color: hsla(var(--brand-950), var(--alpha));
	}

	section.color-brand-950 {
		--section-color: hsla(var(--brand-950), var(--alpha));
	}

	// container.
	.container {
		margin-inline: auto;
		max-width: var(--container-width);
		padding-left: var(--space-l);
		padding-right: var(--space-l);
	}
</style>
