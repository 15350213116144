<template>
	<div class="theme-icon">
		<component :class="this.data.fill" :is="data.component"></component>		
	</div>
</template>

<script>
	export default {
		name: "Icon",

		props: [
			'data'
		]
	}
</script>

<style lang="scss" scoped>
	.theme-icon svg {
		width: 100px;
		height: 100px;
	}
</style>