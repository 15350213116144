<template>
	<div class="theme-image" :class="typeof data.gradient !== 'undefined' && true === data.gradient ? 'theme-gradient' : false">
		<AdvancedImage v-if="typeof this.preload !== 'undefined' && true === this.preload" preload :width="width" :height="height" class="theme-image__element" :cldImg="img" :alt="data.alt" />
		<AdvancedImage v-else :width="width" :height="height" class="theme-image__element" :cldImg="img" :alt="data.alt" />
	</div>
</template>

<script>
	// eslint-disable-next-line
	import { AdvancedImage } from '@cloudinary/vue';

	// eslint-disable-next-line
	import { Cloudinary } from '@cloudinary/url-gen';
	import {scale} from "@cloudinary/url-gen/actions/resize";

	export default {
		name: "Image",

		props: [
			'data',
			'width',
			'height',
			'preload'
		],

		data() {
			return {
				img: false
			}
		},

		components: {
			AdvancedImage
		},

		created() {
			const cld = new Cloudinary(
				{
					cloud: {
						cloudName: "kalunacloud"
					}
				}
			);

			this.img = cld.image('smwayprops/' + this.data.src.replace('.png', ''));

			if (typeof this.width !== 'undefined') {
				this.img.resize(scale().width(this.width.replace('px', '')));
			}

			this.img.format('auto');
			this.img.quality(70);
		}
	}
</script>

<style lang="scss" scoped>
	.theme-image img {
		object-fit: cover;
		height: inherit;
	}

	.theme-image.theme-gradient {
		position: relative; 

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(215deg, hsla(var(--brand-400), .33) 0%, hsla(var(--brand-950), .9) 100%);
		}
	}
</style>