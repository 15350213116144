<template>
	<section class="form">
		<div class="identifier" v-if="typeof data.id !== 'undefined'" :id="data.id"></div>

		<div class="container">
			<div class="form-wrapper">
				<div class="form-row row align-items-center" :class="data.direction">
					<div class="form-column col-12 col-lg-6">
						<component width="700px" height="700px" class="form-image" :is="'image'" :data="data.image"></component>
					</div>

					<div class="form-column form-body col-12 col-lg-6">
						<div class="form-body_clamp flow-m">
							<component class="form-title" v-if="Object.values(data.title).length > 0" :is="'title'" :data="data.title"></component>
							
							<component class="form-copy" v-if="typeof data.copy !== 'undefined'" :is="'copy'" :data="data.copy"></component>

							<form @submit.prevent="submit_form(data.form)" id="formElement">
								<div class="row form_element-row">
									<div class="form-group col-12 col-md-6 flow-2xs">
										<label for="first_name">First name <span class="color-brand-400">(*)</span></label>

										<input required v-model="formData.first_name" type="text" id="first_name" name="first_name">
									</div>

									<div v-if="'contact' === data.form" class="form-group col-12 col-md-6 flow-2xs">
										<label for="surname">Surname <span class="color-brand-400">(*)</span></label>

										<input required v-model="formData.surname" type="text" id="surname" name="surname">
									</div>

									<div class="form-group col-12 col-md-6 flow-2xs">
										<label for="email_address">Email address <span class="color-brand-400">(*)</span></label>

										<input required v-model="formData.email_address" type="email" id="email_address" name="email_address">
									</div>

									<div v-if="'contact' === data.form" class="form-group col-12 col-md-6 flow-2xs">
										<label for="telephone_number">Telephone number <span class="color-brand-400">(*)</span></label>

										<input required v-model="formData.telephone_number" type="phone" id="telephone_number" name="telephone_number">
									</div>

									<div v-if="'contact' === data.form" class="form-group col-12">
										<label for="message">Your message</label>

										<textarea v-model="formData.message" name="message" id="message" cols="8" rows="10"></textarea>
									</div>

									<div class="form-group col-12 flow-2xs">
										<p class="font-base legend">Privacy</p>

										<label :for="'privacy_' + data.form">
											<input v-model="formData.privacy" type="checkbox" name="privacy" :id="'privacy_' + data.form" value="1">

											I have read the <a href="/privacy-policy" target="_blank" title="Click to read the privacy policy">privacy policy</a>.
										</label>
									</div>

									<div class="form-group col-12">
										<button class="button bg-brand-400 color-brand-950">
											Sign up
										</button>
									</div>
								</div>
							</form>

							<div v-if="typeof message.text !== 'undefined'" class="bg-brand-100 color-brand-950 form-message">
								{{ message.text }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import Mailgun from 'mailgun.js';
	import FormData from 'form-data';
	import axios from 'axios';

	export default {
		name: "Form",

		props: [
			'data'
		],

		data() {
			return {
				formData:	{},
				message: 	{}
			}
		},

		methods: {
			submit_form: function(form) {
				if ('contact' === form) {	
					let mailgun 	= new Mailgun(FormData);

					const mg = mailgun.client(
						{	
							username: 	'smwayprops', 
							key: 		process.env.VUE_APP_MG_API_KEY,
							url: 		'https://api.eu.mailgun.net'
						}
					);

					// sanitise the data.
					let html = '';
					html += "<h1>New S.Mitchway Properties LTD website enquiry</h1>";

					if (Object.values(this.formData).length <= 0) {
						return;
					} 

					Object.values(this.formData).forEach((value, index) => {
						if (typeof value === 'undefined' || "" === value ) {
							return;
						}

						html += '<p><span style="text-transform: capitalize;">' + Object.keys(this.formData)[index].replaceAll('_', ' ') + '</span>: <strong>' + value + '</strong></p>';
					});

					mg.messages.create(
						process.env.VUE_APP_MG_DOMAIN,
						{
							from: 		"S.Mitchway Properties LTD <noreply@send.smitchwayproperties.co.uk>",
							to: 		process.env.VUE_APP_MG_ADMIN_EMAIL,
							subject: 	"New S.Mitchway Properties LTD website enquiry",
							html: 		html
						}
					)
					.then(
						() => {
							this.formData = {};

							this.message = {
								text: 	'Your message is being sent, we\'ll contact you within 24-48 hours'
							}
						}
					)
					.catch(
						() => {
							this.message = {
								text: 	'Sorry, it wasn\'t possible to send your message. Please try again later.'
							}

						}
					);
				} else {
					axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

					axios.post('/signup', {
						name: this.formData.first_name,
						email: this.formData.email_address,
						apiKey: process.env.VUE_APP_MC_API_KEY,
						server: process.env.VUE_APP_MC_API_SERVER,
						audience: process.env.VUE_APP_MC_API_AUDIENCE
					})
					.then( response => {
						if ( 200 === response.data.status ) {
							this.formData = [];
							this.message = {
								text: 'Thanks for signing up. Check your email to confirm sign up.'
							};
						}

						if ( 400 === response.data.status ) {
							this.message = {
								text: 'There was an error signing you up—please try again, or try a different email address.'
							};
						}
					})
					.catch( () => {
						this.message = {
							text: 'There was an error signing you up—please try again, or try a different email address.'
						};
					})
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	label {
		display: block;
		font-size: var(--font-small);
		font-weight: 700;
	}

	input {
		height: 55px;
		border-radius: 0px;
		border: 2px solid hsla(var(--brand-950), .1);
		width: 100%;
		padding-left: var(--space-s);

		&:focus,
		&:focus-within {
			outline: none;
			border-color: hsla(var(--brand-950), .66);
		}

		&[type="checkbox"] {
			display: inline-block;
			vertical-align: middle;
			margin-top: -2px;
			width: var(--space-s);
			height: var(--space-s);
		}
	}

	.button {
		cursor: pointer;
		--anchor-color: inherit;
		font-family: var(--family-heading);
		font-size: var(--font-base);
		font-weight: 900;
		letter-spacing: inherit;
		border: 3px solid transparent;
		padding: 0 var(--space-m);
		display: inline-block;
		vertical-align: middle;
		line-height: 49px;
		text-decoration: none;

		&:hover {
			border-color: var(--section-color);
			color: var(--section-color);
			background-color: transparent;
		}
	}

	textarea {
		max-height: 125px;
		border-radius: 0px;
		border: 2px solid hsla(var(--brand-950), .1);
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		padding: var(--space-s);

		&:focus,
		&:focus-within {
			outline: none;
			border-color: hsla(var(--brand-950), .66);
		}
	}

	.form {
		&-row {
			--theme-gutter-y: var(--space-xl);

			&.direction-alt {
				flex-direction: row-reverse;

				.theme-image {
					margin-left: auto;
				}

				.form-body:before {
					display: none;
				}

				.form-body_clamp {
					margin-left: 0;
				}
			}
		}

		&-column {
			padding-left: var(--space-l);
			padding-right: var(--space-l);

			@include media-breakpoint-down(xl) {
				max-width: 76ch;
				margin-inline: auto;
			}
		}

		&-image {
			clip-path: polygon(33.33% 0%, 100% 0, 100% 0, 100% calc(100% - 33.33%), calc(100% - 33.33%) 100%, 0 100%, 0 100%, 0% 33.33%);
			aspect-ratio: 1/1;
			max-width: 700px;
		}

		&-body {
			position: relative;

			a {
				--anchor-color: hsla(var(--brand-700), var(--aplha));
			}

			@include media-breakpoint-up(lg) {
				&:before {
					content: "";
					position: absolute;
					z-index: -1;
					top: 50%;
					right: var(--theme-gutter-x);
					width: 150%;
					height: calc(100% + calc(var(--space-3xl) * 2));
					background-color: hsla(var(--brand-50), var(--alpha));
					transform: translateY(-50%);				
				}
			}

			&_clamp {
				max-width: 70ch;
				margin-inline: auto;
			}
		}
	}

	.form-message {
		border-left: 5px solid currentColor;
		font-weight: 500;
		padding: var(--space-s);
		font-size: var(--font-reset);
	}

	.form_element-row {
		--theme-gutter-y: var(--space-m);
	}

	.legend {
		font-weight: 700;
	}
</style>