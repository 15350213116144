<template>
	<section v-if="Object.values(data.icons).length > 0" class="icons">
		<div class="identifier" v-if="typeof data.id !== 'undefined'" :id="data.id"></div>
		
		<div class="container">
			<div class="row">
				<div v-for="(icon, index) in data.icons" :key="index" class="col-12 col-md-6 col-lg-3">
					<article class="flow-m">
						<component v-if="icon.icon" :data="icon.icon" :is="'icon'" class="text-center"></component>
						
						<component class="icon-title text-center" v-if="typeof icon.title !== 'undefined'" :is="'title'" :data="icon.title"></component>

						<component class="icon-copy" v-if="typeof icon.copy !== 'undefined'" :is="'copy'" :data="icon.copy"></component>
					</article>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: "Icons",

		props: [ 
			'data'
		]
	}
</script>

<style lang="scss" scoped>
	.icons {
		.row {
			--theme-gutter-y: var(--space-l);
			--theme-gutter-x: var(--space-l);
		}
	}
</style>