<template>
	<section class="pair">
		<div class="identifier" v-if="typeof data.id !== 'undefined'" :id="data.id"></div>
		
		<div class="container">
			<div class="row align-items-end">
				<div class="pair-column pair-body col-12 col-lg-6">
					<div class="flow-l">
						<component class="pair-title" v-if="Object.values(data.title).length > 0" :is="'title'" :data="data.title"></component>
						
						<component class="pair-copy" v-if="typeof data.copy !== 'undefined'" :is="'copy'" :data="data.copy"></component>

						<div v-if="Object.values(data.buttons).length > 0">
							<div class="row align-items-center">
								<div v-for="(button, index) in data.buttons" :key="index" class="col-auto">
									<component :is="'button'" :data="button"></component>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="pair-column pair-column__image col-12 col-lg-6">
					<div class="pair-image">
						<component :is="'image'" :data="data.image" width="700px" height="700px"></component>

						<lines class="pair-lines" />
					</div>
				</div>		
			</div>			
		</div>
	</section>
</template>

<script>
	export default {
		name: "Pair",

		props: [ 
			'data'
		]
	}
</script>

<style lang="scss" scoped>
	.pair {
		.row {
			--theme-gutter-y: var(--space-xl);
		}

		&-column {
			max-width: 76ch;
			margin-inline: auto;
		}

		&-body {
			order: 1;

			@include media-breakpoint-up(lg) {
				order: 0;
			}
		}

		&-column__image {
			order: 0;

			@include media-breakpoint-up(lg) {
				order: 1;
			}
		}

		&-image {
			position: relative;
			z-index: 1;

			&::v-deep img {
				clip-path: polygon(33.33% 0%, 100% 0, 100% 0, 100% calc(100% - 33.33%), calc(100% - 33.33%) 100%, 0 100%, 0 100%, 0% 33.33%);
				aspect-ratio: 1/1;
				max-width: 700px;
			}

			&::v-deep img {
				margin-top: calc(var(--space-3xl) * -1.25);
			}

			@include media-breakpoint-up(lg) {
				&::v-deep img {
					margin-top: calc(var(--space-3xl) * -3);
				}
			}
		}

		&-lines {
			position: absolute;
			z-index: -1;
			top: calc(var(--space-3xl) * -1);
			right: calc(var(--space-3xl) * -1);
			width: 50%;
			min-width: 260px;
			height: auto;
			fill: hsla(var(--brand-0), 1);
		}
	}
</style>