<template>
	<header class="masthead color-brand-0" id="masthead">
		<div class="container">
			<div class="row justify-content-between align-items-center">
				<div class="masthead-logo">
					<router-link v-if="'Home' !== $route.name" :to="{ path: '/' }" title="Click to go back to the top">
						<smicon class="masthead-icon fill-brand-0" />
					</router-link>

					<a v-else href="#" title="Click to go back to the top">
						<smicon class="masthead-icon fill-brand-0" />
					</a>
				</div>

				<nav class="masthead-navigation family-heading font-base">
					<ul class="row justify-content-center">
						<li>
							<a title="Click to scroll to read about us" href="/#about">About</a>
						</li>
						<li>
							<a title="Click to scroll to our services" href="/#process">Process</a>
						</li>
						<li>
							<a title="Click to scroll to our signup area for our weekly emails" href="/#contact">Contact</a>
						</li>
					</ul>
				</nav>

				<div class="social-media">
					<ul class="row justify-content-center">
						<li>
							<a target="_blank" href="https://www.instagram.com/s.mitchway_properties" title="Follow us on Instagram">
								<instagram/>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</header>

	<button @click="toggle_overlay()" class="overlay-trigger_button" title="Toggle the visibility of the overlay menu">
		<bars/>
	</button>

	<div v-show="true === overlay_open" class="overlay flow-xl">
		<router-link v-if="'Home' !== $route.name" :to="{ path: '/' }" title="Click to go back to the top">
			<logo class="overlay-icon fill-brand-0" />
		</router-link>

		<a v-else href="#" title="Click to go back to the top">
			<logo class="overlay-icon fill-brand-0" />
		</a>

		<nav class="overlay-navigation family-heading font-base">
			<ul>
				<li class="font-large">
					<a @click="overlay_open = false" title="Click to scroll to read about us" href="/#about">About</a>
				</li>
				<li class="font-large">
					<a @click="overlay_open = false" title="Click to scroll to our services" href="/#process">Process</a>
				</li>
				<li class="font-large">
					<a @click="overlay_open = false" title="Click to scroll to our signup area for our weekly emails" href="/#contact">Contact</a>
				</li>
			</ul>
		</nav>

		<div class="social-media flow-m">
			<ul class="row align-items-center">
				<li>
					<p class="color-brand-0 family-heading">Follow us:</p>
				</li>

				<li>
					<a target="_blank" href="https://www.instagram.com/s.mitchway_properties" title="Follow us on Instagram">
						<instagram/>
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Masthead",

		data() {
			return {
				overlay_open: false
			}
		},

		methods: {
			toggle_overlay: function() {
				if (false === this.overlay_open) {
					this.overlay_open = true;
				} else {
					this.overlay_open = false;
				}
			}
		},

		mounted() {
			window.addEventListener('scroll', () => {
				let masthead = document.getElementById('masthead');

				if (! masthead) {
					return;
				}

				let scroll 	= window.scrollY;
				let height	= masthead.clientHeight;

				if (scroll > height) {
					if (false === masthead.classList.contains('bg-brand-950')) {
						masthead.classList.add('bg-brand-950');
					}
				} else {
					masthead.classList.remove('bg-brand-950');
				}
			});
		}
	}
</script>

<style lang="scss" scoped>
	nav {
		ul {
			padding-left: 0;
		}

		li {
			list-style: none;
			padding-left: 0;

			@include media-breakpoint-up(sm) {
				&:not(:first-child) {
					list-style: disc;
					padding-left: var(--space-s);
				}
			}
		}

		li:not(:last-child) {
			margin-right: var(--space-m);
		}

		a {
			--anchor-color: hsla(var(--brand-0), var(--alpha));

			&:hover {
				--anchor-color: hsla(var(--brand-400), var(--alpha));
			}
		}
	}

	.social-media {
		ul {
			list-style: none;
			margin-top: 0;
			margin-bottom: 0;
			padding: 0;

			svg {
				width: var(--space-m);
				height: auto;
			}
		}
	}

	.masthead {
		position: fixed;
		z-index: 10;
		top: 0;
		left: 0;
		width: 100%;
		padding-top: var(--space-m);
		padding-bottom: var(--space-m);
		transition: background ease-in-out 225ms;
		height: var(--masthead-height);

		&:before {
			content: '';
			position: absolute;
			z-index: -1;
			top: 0;
			left: 0;
			width: 100%;
			height: 0px;
			box-shadow: 0 0 100px 60px hsla(var(--brand-950), 1);
		}

		&-icon {
			width: 90px;
			height: auto;
		}

		a:not(:hover) {
			--anchor-color: currentColor;
		}

		&-navigation {
			display: none;

			@include media-breakpoint-up(md) {
				display: block;
			}
		}

		nav {
			ul {
				padding: 0;
				margin: 0;
			}
		}
	}

	.overlay {
		position: fixed;
		z-index: 10;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow-x: hidden;
		overflow-y: scroll;
		background-color: hsla(var(--brand-950), var(--alpha));
		padding: var(--space-2xl);

		@include media-breakpoint-up(md) {
			display: none;
		}

		&-icon {
			width: 240px;
			height: auto;
			margin-left: 0;
		}
	}

	@keyframes fadeUp {
		0% {
			transform: translateY(var(--space-3xl));
			opacity: 0;
		}

		100% {
			transform: translateY(0);
			opacity: 1;
		}	
	}

	.overlay-trigger_button {
		opacity: 0;
		will-change: transform;
		animation: fadeUp 222ms 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
		position: fixed;
		z-index: 11;
		bottom: var(--space-l);
		right: var(--space-l);
		display: inline-block;
		border: none;
		outline: none;
		background-color: transparent;
		padding: 0;
		width: var(--space-2xl);
		height: var(--space-2xl);
		border-radius: 100%;
		background-color: hsla(var(--brand-400), var(--alpha));
		box-shadow: 0 0 var(--space-xl) 0 hsla(var(--brand-400), .66);

		svg {
			fill: hsla(var(--brand-50), var(--alpha));
			width: 40%;
			height: auto;
			margin-top: -5px;
		}

		@include media-breakpoint-up(md) {
			display: none;
		}
	}
</style>