<template>
	<div class="theme-copy">
		<p v-html="this.data"></p>
	</div>	
</template>

<script>
	export default {
		name: "Copy",

		props: [ 
			'data'
		]
	}
</script>