import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// new up app.
const app = createApp(App);

// components.
import components from '@/components';

Object.values( components ).forEach( component => {
	app.component(component.name.toLowerCase(), component);
} );

// partials.
import partials from '@/partials';

Object.values( partials ).forEach( partial => {
	app.component(partial.name.toLowerCase(), partial);
} );

// icons.
import icons from '@/icons';

Object.values( icons ).forEach( icon => {
	app.component(icon.name.toLowerCase(), icon);
} );

app.use(store).use(router).mount('#app');